import { isFunction, notNullNotUndefined } from "./objectUtils"
import { isAnyElementExistingAndVisible } from "./windowUtils"

export const joinClasses = (arr) => {
    return arr.filter(i=>i!==null&&i!==undefined).join(' ')
}

export const hideDown = (size) => {
    return theme=>({
        [theme.breakpoints.down(size)]: {
            display: 'none',
        }
    })
}

/**
 * 
 * @param {*} classes 
 *      Classes to search 
 * @param {*} params 
 *      Params returned by container query
 * @returns 
 */
export const getClassesByContainerQuery = (classes, params, defaultClass) => {
    notNullNotUndefined(classes)
    notNullNotUndefined(params)

    const classesEffective = []
    if (defaultClass) {
        classesEffective.push(defaultClass)
    }
    Object.keys(params).forEach((k)=>{
        const val = params[k]
        if (val === true) {
            const classDef = classes[k]
            if (classDef) {
                classesEffective.push(classDef)
            }
        }
    })
    return classesEffective
}

export const getClassNameByContainerQuery = (classes, params, defaultClass) => {
    return joinClasses(getClassesByContainerQuery(classes, params, defaultClass))
}

export const isMuiDialogOpen = () => {
    return isAnyElementExistingAndVisible('.MuiBackdrop-root')
}

export const enhanceBreakpointValues = (style, theme) => {
    if (!style) {
        return style
    }
    if (isFunction(style)) {
        return style
    }
    const deleteKeys = []
    const enhanced = {}

    for (const [key, value] of Object.entries(style)) {
         if (key.startsWith('themeBreakpoints')) {
            deleteKeys.push(key)

            const command = key.substring(16,key.length)
            const direction = command.toLowerCase().startsWith('up') ? 'up' : 'down'

            const breakpoint = command.substring(direction.length, command.length).toLowerCase()
            if (direction === 'up') {
                enhanced[theme.breakpoints.up(breakpoint)] = value
            } else if (direction === 'down') {
                enhanced[theme.breakpoints.down(breakpoint)] = value
            }

         }
    }

    const converted = {
        ...style,
        ...enhanced,
    }

    for (const k of deleteKeys) {
        delete converted[k]
    }
    return converted
}